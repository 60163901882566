import React from "react"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import {
  blockClass,
  containerClass,
  innerWrapperClass,
  textBlockClass
} from "./PlainText.module.scss"
import SectionHeading from "../UI/SectionHeading"

export const fragment = graphql`
  fragment plainTextFragment on WpPage_Flexlayouts_FlexibleLayouts_PlainText {
    sectionHeading
    headingType
    text
  }
`

const PlainText = ({ sectionHeading, text, headingType }) => {
  let displayText
  if (text) {
    const linkRegex = new RegExp(process.env.SITE_URL, "g")
    displayText = text.replace(/&nbsp;/g, "")
    displayText = displayText.replace(/<div/g, "<p")
    displayText = displayText.replace(/<\/div/g, "</p")
    displayText = displayText.replace(/<b>/g, "<strong>")
    displayText = displayText.replace(/<\/b>/g, "</strong>")
    displayText = displayText.replace(linkRegex, "")
  }

  return (
    /*
     * HACK FIX TO PADDING ISSUE ON PLAIN TEXT COMPONENT
     * commandeers rendundant "b" HTML element to target first and last  component use on a page (also see: src/styles/index.scss)
     */
    <b className={`${blockClass} bg-pale-grey`}>
      <Container className={containerClass}>
        <div class="plain-text-block-wrapper">
          <div className={innerWrapperClass}>            
            <SectionHeading className="pt-4 pb-3" type={headingType} text={sectionHeading} />            
            {text && (
              <div
                className={textBlockClass}
                dangerouslySetInnerHTML={{ __html: displayText
                }}
              />
            )}
          </div>
        </div>
      </Container>
    </b>
  )
}

export default PlainText